import React from "react";
import { motion } from "framer-motion";
import { RiCodeBoxFill, RiStarLine } from "react-icons/ri";
import { SiReact, SiLaravel, SiTailwindcss, SiNodedotjs } from "react-icons/si";

const experiences = [
    {
        title: "Fullstack Developer",
        company: "MarginTop Solutions",
        duration: "November 2024 - Present",
        logo: "/images/marginTop-logo.png",
        description: "Pioneering innovative web application development, crafting robust and scalable solutions using cutting-edge technologies. Implemented responsive design principles and optimized user experience across multiple platforms.",
        technologies: [
            { icon: SiReact, name: "React" },
            { icon: SiLaravel, name: "Laravel" },
            { icon: SiTailwindcss, name: "Tailwind CSS" },
            { icon: SiNodedotjs, name: "Node.js" }
        ],
        highlights: [
            "Developed complex web applications",
            "Optimized performance and user experience",
            "Implemented modern frontend architectures"
        ]
    },
];

const Experience = () => {
    return (
        <section
            id="experience"
            className="relative pb-16 pt-4"
        >
            <div className="container mx-auto px-6 relative z-10">
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.7 }}
                    className="text-center mb-16"
                >
                    <h2 className="text-5xl font-extrabold text-white mb-4 tracking-tight">
                        Professional Journey
                    </h2>
                    <p className="text-xl text-gray-300 max-w-2xl mx-auto">
                        Transforming ideas into innovative digital solutions with passion and precision
                    </p>
                </motion.div>

                <div className="max-w-4xl mx-auto space-y-8">
                    {experiences.map((experience, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 0.6,
                                type: "spring",
                                stiffness: 120
                            }}
                            className="bg-white/10 backdrop-blur-lg rounded-3xl border border-white/20 hover:border-white/40 transition-all duration-300 transform hover:-translate-y-3 shadow-xl"
                        >
                            <div className="p-8 relative">
                                <div className="absolute top-1 right-4 bg-white/10 text-white/80 px-4 py-2 rounded-full text-sm font-semibold">
                                    {experience.duration}
                                </div>

                                <div className="flex items-center mb-6 pt-2">
                                    {experience.logo && (
                                        <div className="mr-6">
                                            <img
                                                src={experience.logo}
                                                alt={`${experience.company} logo`}
                                                className="w-20 h-20 object-contain rounded-full shadow-md"
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <h3 className="text-3xl font-bold text-white mb-2">
                                            {experience.title}
                                        </h3>
                                        <p className="text-xl text-gray-300 flex items-center">
                                            <RiCodeBoxFill className="mr-2 text-white/70" />
                                            {experience.company}
                                        </p>
                                    </div>
                                </div>

                                <p className="text-base text-gray-300 leading-relaxed mb-6">
                                    {experience.description}
                                </p>

                                <div className="mb-6">
                                    <div className="flex items-center space-x-4 mb-4">
                                        <span className="text-gray-300 font-medium">Technologies:</span>
                                        {experience.technologies.map((tech, techIndex) => (
                                            <div
                                                key={techIndex}
                                                className="tooltip group"
                                                title={tech.name}
                                            >
                                                <tech.icon
                                                    className="text-3xl text-white/70 
                                                    hover:text-white transition-colors 
                                                    group-hover:scale-125 transform"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="bg-white/10 p-4 rounded-xl">
                                    <h4 className="text-lg font-semibold text-white mb-3 flex items-center">
                                        <RiStarLine className="mr-2 text-white/70" />
                                        Key Highlights
                                    </h4>
                                    <ul className="space-y-2">
                                        {experience.highlights.map((highlight, hIndex) => (
                                            <li
                                                key={hIndex}
                                                className="flex items-center text-gray-300"
                                            >
                                                <span className="mr-2 text-white/70">•</span>
                                                {highlight}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Experience;