import React, { useState, useEffect } from 'react';
import { Github, Linkedin, Instagram, Twitter, Facebook } from 'lucide-react';

const Home = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeIcon, setActiveIcon] = useState(null);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const social = [
    { name: "Github", link: "https://github.com/rudalkunwar/", icon: Github, color: "#333" },
    { name: "LinkedIn", link: "https://np.linkedin.com/in/rudal-kunwar-4561bb260", icon: Linkedin, color: "#0077B5" },
    { name: "Instagram", link: "#", icon: Instagram, color: "#E4405F" },
    { name: "Twitter", link: "#", icon: Twitter, color: "#1DA1F2" },
    { name: "Facebook", link: "https://www.facebook.com/kuns.ruzal", icon: Facebook, color: "#1877F2" }
  ];

  return (
    <section id='home' className="relative min-h-screen w-full flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0 bg-noise opacity-20"></div>

      <div className="relative z-10 max-w-4xl w-full px-6 mb-24 mx-auto">
        <div className={`text-center transform transition-all duration-1000 ${isLoaded ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
          {/* Name with Glitch Effect */}
          <div className="relative inline-block group">
            <h1 className="text-6xl md:text-8xl font-black text-transparent bg-clip-text bg-gradient-to-r from-emerald-400 to-white
              tracking-tighter leading-tight mb-4 animate-text-shimmer bg-[length:200%_100%]">
              Rudal Kunwar
            </h1>
            <div className="absolute inset-0 -z-10 bg-emerald-500/20 blur-2xl opacity-0 group-hover:opacity-50 transition-opacity duration-500"></div>
          </div>

          {/* Profession with Animated Typing Effect */}
          <div className="relative inline-block mb-8">
            <p className="text-3xl font-bold text-gray-200 tracking-wide type-effect">
              Fullstack Developer
            </p>
            <div className="absolute -inset-2 bg-gradient-to-r from-purple-500/20 to-emerald-500/20 blur-lg"></div>
          </div>

          {/* Social Icons */}
          <div className="flex flex-wrap justify-center gap-6">
            {social.map((item) => (
              <a
                key={item.name}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="group relative"
                onMouseEnter={() => setActiveIcon(item.name)}
                onMouseLeave={() => setActiveIcon(null)}
              >
                <div className={`p-2 rounded-full transition-all duration-500 
                  ${activeIcon === item.name
                    ? 'bg-emerald-500/20 scale-110 rotate-6'
                    : 'bg-gray-800/30 hover:bg-emerald-500/10'}
                  backdrop-blur-md border border-gray-700/50 
                  hover:border-emerald-500/50
                `}>
                  <item.icon
                    size={28}
                    className={`transition-colors duration-300 
                      ${activeIcon === item.name
                        ? 'text-emerald-400'
                        : 'text-gray-400 group-hover:text-emerald-400'}
                    `}
                  />
                </div>
                {activeIcon === item.name && (
                  <div className="absolute -bottom-10 left-1/2 -translate-x-1/2 
                    bg-emerald-800/80 text-white text-xs px-3 py-1 rounded-md 
                    after:content-[''] after:absolute after:top-[-5px] 
                    after:left-1/2 after:-translate-x-1/2 
                    after:border-l-8 after:border-r-8 
                    after:border-b-8 after:border-l-transparent 
                    after:border-r-transparent 
                    after:border-b-emerald-800/80">
                    {item.name}
                  </div>
                )}
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Styling Fix */}
      <style jsx>{`
        @keyframes text-shimmer {
          from { background-position: 200% 0; }
          to { background-position: -200% 0; }
        }

        .animate-text-shimmer {
          animation: text-shimmer 3s ease-in-out infinite;
        }

        .bg-noise {
          background-image: 
            radial-gradient(rgba(16, 185, 129, 0.1) 1px, transparent 1px),
            radial-gradient(rgba(16, 185, 129, 0.1) 1px, transparent 1px);
          background-position: 0 0, 25px 25px;
          background-size: 50px 50px;
        }
      `}</style>
    </section>
  );
};

export default Home;
