import React, { useState } from "react";
import { Link } from "react-scroll";
import {
  RiHome2Fill,
  RiUserFill,
  RiFileList2Fill,
  RiContactsFill,
  RiDownload2Fill,RiBriefcaseFill
} from "react-icons/ri";
import { BsFillBriefcaseFill } from "react-icons/bs";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDownload = () => {
    const anchor = document.createElement("a");
    anchor.href = "/cv/RudalKunwarCV.pdf";
    anchor.download = "RudalKunwarCV.pdf";
    anchor.click();
  };

  // Common Link props for consistency
  const linkProps = {
    spy: true,
    smooth: true,
    duration: 500,
    offset: -100, // Offset to account for fixed header
    spyThrottle: 100, // Throttle spy events for better performance
  };

  // Common classes for links
  const linkClasses = "text-base cursor-pointer font-medium text-white transition-all duration-200 hover:text-green-400";
  const activeLinkClasses = "!text-green-400"; // Using ! to ensure override

  const navLinks = [
    { to: "home", icon: RiHome2Fill, text: "Home" },
    { to: "about", icon: RiUserFill, text: "About" },
    { to: "skills", icon: RiFileList2Fill, text: "Skills" },
    { to: "projects", icon: BsFillBriefcaseFill, text: "Projects" },
    { to: "experience", icon: RiBriefcaseFill, text: "Experience" },
    { to: "contact", icon: RiContactsFill, text: "Contact" },
];

  return (
    <header className="pb-6 bg-black/50 backdrop-blur-sm lg:pb-0 sticky top-0 w-full z-50 border-b border-white/10">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* lg+ */}
        <nav className="flex items-center justify-between h-16 lg:h-20">
          <div className="flex-shrink-0">
            <Link
              {...linkProps}
              to="home"
              className="flex cursor-pointer text-white font-bold hover:text-green-400 transition-colors"
            >
              RudalKunwar
            </Link>
          </div>

          <button
            type="button"
            className="inline-flex p-2 text-white transition-all duration-200 rounded-md lg:hidden hover:bg-white/10"
            onClick={toggleMenu}
          >
            {menuOpen ? (
              <svg className="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </button>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex lg:items-center lg:ml-auto lg:space-x-10">
            {navLinks.map(({ to, icon: Icon, text }) => (
              <Link
                key={to}
                to={to}
                {...linkProps}
                className={linkClasses}
                activeClass={activeLinkClasses}
              >
                <Icon className="inline-block mr-1" /> {text}
              </Link>
            ))}
          </div>

          <button
            onClick={handleDownload}
            className="items-center justify-center hidden px-4 py-3 ml-10 text-base cursor-pointer font-semibold text-white transition-all duration-200 bg-green-500 border border-transparent rounded-md lg:inline-flex hover:bg-green-600"
          >
            <RiDownload2Fill className="inline-block mr-1" /> Download CV
          </button>
        </nav>

        {/* Mobile Navigation */}
        <nav
          className={`pt-4 pb-6 bg-black/50 backdrop-blur-sm border border-white/10 rounded-md lg:hidden ${menuOpen ? "" : "hidden"
            }`}
        >
          <div className="flow-root">
            <div className="flex flex-col px-6 -my-2 space-y-1">
              {navLinks.map(({ to, icon: Icon, text }) => (
                <Link
                  key={to}
                  to={to}
                  {...linkProps}
                  className="inline-flex items-center py-2 text-base cursor-pointer font-medium text-white transition-all duration-200 hover:text-green-400"
                  activeClass={activeLinkClasses}
                  onClick={toggleMenu}
                >
                  <Icon className="inline-block mr-1" /> <span>{text}</span>
                </Link>
              ))}
            </div>
          </div>

          <div className="px-6 mt-6">
            <button
              onClick={handleDownload}
              className="inline-flex items-center justify-center w-full px-4 py-3 text-base cursor-pointer font-semibold text-white transition-all duration-200 bg-green-500 border border-transparent rounded-md hover:bg-green-600"
            >
              <RiDownload2Fill className="inline-block mr-1" />
              <span>Download CV</span>
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;