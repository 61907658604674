import React from "react";

const About = () => {
  return (
    <section
      id="about"
      className="pb-36 pt-4 relative z-10"
    >
      <div className="absolute z-20 inset-0 bg-gradient-to-b from-black/0 via-black/50 to-black/0 pointer-events-none" />

      <h2 className="text-4xl md:text-6xl font-bold text-white mb-8 text-center">
        ABOUT
      </h2>
      <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 mb-10 md:mb-0 md:pr-8 flex justify-center">
            <div className="relative">
              {/* Background Glow Effect */}
              <div className="absolute -inset-2 bg-green-500/50 rounded-full blur-xl opacity-75"></div>

              {/* Larger Image */}
              <img
                src="images/kuns.jpg"
                alt="Rudal Kunwar"
                className="relative z-10 object-cover rounded-full w-96 h-96 md:w-[28rem] md:h-[28rem] border-4 border-green-500 shadow-lg"
              />
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <h1 className="pl-2 text-3xl md:text-4xl font-bold border-l-8 border-green-500 mb-6 px-4 py-2 text-white">
              Hi, I'm Rudal Kunwar
            </h1>
            <p className="mt-2 mb-6 text-lg leading-7 text-gray-300 px-4">
              Highly motivated full-stack developer with a passion for creating dynamic, user-friendly web applications. My
              work is driven by a commitment to delivering scalable, high-performance solutions. With expertise in both
              frontend and backend, I excel in responsive design, API integration, and database management, always striving
              to improve user experience and system efficiency.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;